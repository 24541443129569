import { defineMessages } from "react-intl";
import {
    MODES_OF_TRAVEL,
    ANALYSIS_TYPES,
    TTravelMode,
} from "@common/constants/analysis.constants";
import { MEASUREMENT_UNITS, SPEED_UNITS } from "@common/constants/measurementUnits.constants";
import {
    getOnlyStartPresetRange,
    getIncrementedRange,
    getOnlyStartPresetFromArray,
} from "./addOns.helpers";

export const ADD_ONS_TAB_SECTIONS = {
    GEOGRAPHIES: { code: "geographies", isAddOnsSection: false },
    TOP_ROUTES: { code: "topRoutes", isAddOnsSection: false },
    COMMERCIAL_VEHICLE_FILTERS: { code: "commercialVehicleFilters", isAddOnsSection: true },
    TRIP_ATTRIBUTES: { code: "tripAttributes", isAddOnsSection: true },
    TRAVELER_ATTRIBUTES: { code: "travelerAttributes", isAddOnsSection: true },
    HOME_AND_WORK_LOCATIONS: { code: "homeWorkLocations", isAddOnsSection: true },
    SEGMENT_DURATION: { code: "segmentDuration", isAddOnsSection: false },
    SEGMENT_SPEED: { code: "segmentSpeed", isAddOnsSection: false },
    SPEED_PERCENTILE: { code: "speedPercentile", isAddOnsSection: true },
    TRAVEL_TIME_PERCENTILE: { code: "travelTimePercentile", isAddOnsSection: true },
    AVERAGE_SPEED: { code: "averageSpeed", isAddOnsSection: false },
    SPOT_SPEED: { code: "spotSpeed", isAddOnsSection: false },
    AVERAGE_TRAVEL_TIME: { code: "averageTravelTime", isAddOnsSection: false },
    VALIDATION_DETAILS: { code: "validationDetails", isAddOnsSection: true },
    OTHER_OPTIONS: { code: "otherOptions", isAddOnsSection: false },
};
export type TAddOnsSection = typeof ADD_ONS_TAB_SECTIONS[keyof typeof ADD_ONS_TAB_SECTIONS];

export const SEGMENT_TYPES_HELP_TEXTS = defineMessages({
    DURATION: {
        id: "app.segmentDurationHelpText",
        defaultMessage:
            "This is the trip time measured in seconds between start and end for each segment. Distribution bins are in minutes",
    },
    SPEED_MPH: {
        id: "app.segmentSpeedHelpTextMph",
        defaultMessage:
            "This is the average speed in MPH between start gate and end gate for each segment",
    },
    SPEED_KPH: {
        id: "app.segmentSpeedHelpTextKph",
        defaultMessage:
            "This is the average speed in KPH between start gate and end gate for each segment",
    },
    PERCENTILE_MPH: {
        id: "app.segmentSpeedPercentileHelpTextMph",
        defaultMessage:
            "Analyze speed percentiles in MPH between start gate and end gate for each segment",
    },
    PERCENTILE_KPH: {
        id: "app.segmentSpeedPercentileHelpTextKph",
        defaultMessage:
            "Analyze speed percentiles in KPH between start gate and end gate for each segment",
    },
    OD_PERCENTILE_MPH: {
        id: "app.odSpeedPercentileHelpTextMph",
        defaultMessage:
            "Analyze speed percentiles in MPH between origin and destination for each zone",
    },
    OD_PERCENTILE_KPH: {
        id: "app.odSpeedPercentileHelpTextKph",
        defaultMessage:
            "Analyze speed percentiles in KPH between origin and destination for each zone",
    },
    TRAVEL_TIME_PERCENTILE: {
        id: "app.travelTimePercentileHelpText",
        defaultMessage:
            "Analyze Travel Time percentiles between start gate and end gate for each segment",
    },
});
export type TSegmentTypesHelpTextKey = keyof typeof SEGMENT_TYPES_HELP_TEXTS;
export type TSegmentTypesHelpText =
    typeof SEGMENT_TYPES_HELP_TEXTS[keyof typeof SEGMENT_TYPES_HELP_TEXTS];

const DURATION_DEFAULT_PRESET = [
    { start: 0, end: 10 },
    { start: 10, end: 20 },
    { start: 20, end: 30 },
    { start: 30, end: 40 },
    { start: 40, end: 50 },
    { start: 50, end: 60 },
    { start: 60, end: 70 },
    { start: 70, end: 80 },
    { start: 80, end: 90 },
    { start: 90, end: 100 },
    { start: 100, end: 110 },
    { start: 110, end: 120 },
    { start: 120, end: 130 },
    { start: 130, end: 140 },
    { start: 140, end: 150 },
    { start: 150 },
];

export const SPEED_BINS_END_POINT = 70;

export const TRIP_BIN_TYPES = {
    DURATION: {
        code: "trip_duration_bins",
        name: "Travel Time",
        uiStateName: "tripDuration",
        unit: "minutes",
        units: null,
        showInViz3: true,
        chartType: "totalTrafficByTripDuration",
        binTypeAccessor: "enable_trip_travel_time",
        columnHeaders: [],
        defaultValue: DURATION_DEFAULT_PRESET,
        minValue: null,
        maxValue: null,
        rangeFunction: null,
        defaultValueForTravelMode: {
            [MODES_OF_TRAVEL.BICYCLE.code]: [
                { start: 0, end: 5 },
                { start: 5, end: 10 },
                { start: 10, end: 15 },
                { start: 15, end: 20 },
                { start: 20, end: 25 },
                { start: 25, end: 30 },
                { start: 30 },
            ],
            [MODES_OF_TRAVEL.PEDESTRIAN.code]: [
                { start: 0, end: 5 },
                { start: 5, end: 10 },
                { start: 10, end: 15 },
                { start: 15, end: 20 },
                { start: 20, end: 25 },
                { start: 25, end: 30 },
                { start: 30 },
            ],
            [MODES_OF_TRAVEL.BUS.code]: [
                { start: 0, end: 5 },
                { start: 5, end: 10 },
                { start: 10, end: 15 },
                { start: 15, end: 20 },
                { start: 20, end: 25 },
                { start: 25, end: 30 },
                { start: 30, end: 35 },
                { start: 35, end: 40 },
                { start: 40, end: 45 },
                { start: 45, end: 50 },
                { start: 50, end: 55 },
                { start: 55, end: 60 },
                { start: 60 },
            ],
            [MODES_OF_TRAVEL.RAIL.code]: [
                { start: 0, end: 5 },
                { start: 5, end: 10 },
                { start: 10, end: 15 },
                { start: 15, end: 20 },
                { start: 20, end: 25 },
                { start: 25, end: 30 },
                { start: 30, end: 35 },
                { start: 35, end: 40 },
                { start: 40, end: 45 },
                { start: 45, end: 50 },
                { start: 50, end: 55 },
                { start: 55, end: 60 },
                { start: 60 },
            ],
        },
    },
    LENGTH: {
        code: "trip_length_bins",
        name: "Trip Length",
        uiStateName: "tripLength",
        units: {
            MILES: MEASUREMENT_UNITS.MILES.id,
            KILOMETERS: MEASUREMENT_UNITS.KILOMETERS.id,
        },
        showInViz3: true,
        chartType: "totalTrafficByTripLength",
        binTypeAccessor: "enable_trip_length",
        columnHeaders: [],
        defaultValue: [
            { start: 0, end: 1 },
            { start: 1, end: 2 },
            { start: 2, end: 5 },
            { start: 5, end: 10 },
            { start: 10, end: 20 },
            { start: 20, end: 30 },
            { start: 30, end: 40 },
            { start: 40, end: 50 },
            { start: 50, end: 60 },
            { start: 60, end: 70 },
            { start: 70, end: 80 },
            { start: 80, end: 90 },
            { start: 90, end: 100 },
            { start: 100 },
        ],
        minValue: null,
        maxValue: null,
        rangeFunction: null,
        defaultValueForTravelMode: {
            [MODES_OF_TRAVEL.BICYCLE.code]: {
                [MEASUREMENT_UNITS.MILES.id]: [
                    { start: 0, end: 1 },
                    { start: 1, end: 2 },
                    { start: 2, end: 3 },
                    { start: 3, end: 4 },
                    { start: 4, end: 5 },
                    { start: 5, end: 6 },
                    { start: 6, end: 7 },
                    { start: 7 },
                ],
                [MEASUREMENT_UNITS.KILOMETERS.id]: [
                    { start: 0, end: 1 },
                    { start: 1, end: 2 },
                    { start: 2, end: 3 },
                    { start: 3, end: 5 },
                    { start: 5, end: 7 },
                    { start: 7, end: 9 },
                    { start: 9, end: 12 },
                    { start: 12 },
                ],
            },
            [MODES_OF_TRAVEL.PEDESTRIAN.code]: {
                [MEASUREMENT_UNITS.MILES.id]: [
                    { start: 0, end: 1 },
                    { start: 1, end: 2 },
                    { start: 2, end: 3 },
                    { start: 3, end: 4 },
                    { start: 4, end: 5 },
                    { start: 5 },
                ],
                [MEASUREMENT_UNITS.KILOMETERS.id]: [
                    { start: 0, end: 1 },
                    { start: 1, end: 2 },
                    { start: 2, end: 3 },
                    { start: 3, end: 4 },
                    { start: 4, end: 5 },
                    { start: 5, end: 6 },
                    { start: 6, end: 7 },
                    { start: 7 },
                ],
            },
            [MODES_OF_TRAVEL.BUS.code]: [
                { start: 0, end: 1 },
                { start: 1, end: 2 },
                { start: 2, end: 3 },
                { start: 3, end: 4 },
                { start: 4, end: 5 },
                { start: 5, end: 6 },
                { start: 6, end: 7 },
                { start: 7, end: 8 },
                { start: 8, end: 9 },
                { start: 9, end: 10 },
                { start: 10 },
            ],
            [MODES_OF_TRAVEL.RAIL.code]: [
                { start: 0, end: 1 },
                { start: 1, end: 2 },
                { start: 2, end: 3 },
                { start: 3, end: 4 },
                { start: 4, end: 5 },
                { start: 5, end: 10 },
                { start: 10, end: 15 },
                { start: 15, end: 20 },
                { start: 20, end: 25 },
                { start: 25, end: 30 },
                { start: 30 },
            ],
        },
    },
    SPEED: {
        code: "trip_speed_bins",
        name: "Trip Speed",
        uiStateName: "tripSpeed",
        units: {
            MPH: SPEED_UNITS.MPH.id,
            KPH: SPEED_UNITS.KPH.id,
        },
        showInViz3: true,
        chartType: "totalTrafficByTripSpeed",
        binTypeAccessor: "enable_trip_speed",
        columnHeaders: [],
        defaultValue: [
            { start: 0, end: 10 },
            { start: 10, end: 20 },
            { start: 20, end: 30 },
            { start: 30, end: 40 },
            { start: 40, end: 50 },
            { start: 50, end: 60 },
            { start: 60, end: 70 },
            { start: SPEED_BINS_END_POINT },
        ],
        minValue: null,
        maxValue: null,
        rangeFunction: null,
        presetOptions: {
            "Increments of 10": () =>
                getIncrementedRange({
                    startPoint: 0,
                    endPoint: SPEED_BINS_END_POINT,
                    increment: 10,
                    shouldAddFinalBin: true,
                }),
            "Increments of 5": () =>
                getIncrementedRange({
                    startPoint: 0,
                    endPoint: SPEED_BINS_END_POINT,
                    increment: 5,
                    shouldAddFinalBin: true,
                }),
            "Increments of 2": () =>
                getIncrementedRange({
                    startPoint: 0,
                    endPoint: SPEED_BINS_END_POINT,
                    increment: 2,
                    shouldAddFinalBin: true,
                }),
        },
        defaultValueForTravelMode: {
            [MODES_OF_TRAVEL.BICYCLE.code]: {
                [MEASUREMENT_UNITS.MILES.id]: [
                    { start: 0, end: 2 },
                    { start: 2, end: 4 },
                    { start: 4, end: 6 },
                    { start: 6, end: 8 },
                    { start: 8, end: 10 },
                    { start: 10, end: 12 },
                    { start: 12, end: 14 },
                    { start: 14 },
                ],
                [MEASUREMENT_UNITS.KILOMETERS.id]: [
                    { start: 0, end: 4 },
                    { start: 4, end: 8 },
                    { start: 8, end: 12 },
                    { start: 12, end: 16 },
                    { start: 16, end: 20 },
                    { start: 20, end: 24 },
                    { start: 24, end: 28 },
                    { start: 28 },
                ],
            },
            [MODES_OF_TRAVEL.PEDESTRIAN.code]: {
                [MEASUREMENT_UNITS.MILES.id]: [
                    { start: 0, end: 1 },
                    { start: 1, end: 2 },
                    { start: 2, end: 3 },
                    { start: 3, end: 4 },
                    { start: 4, end: 5 },
                    { start: 5 },
                ],
                [MEASUREMENT_UNITS.KILOMETERS.id]: [
                    { start: 0, end: 2 },
                    { start: 2, end: 4 },
                    { start: 4, end: 6 },
                    { start: 6, end: 8 },
                    { start: 8, end: 10 },
                    { start: 10, end: 12 },
                    { start: 12 },
                ],
            },
            [MODES_OF_TRAVEL.BUS.code]: [
                { start: 0, end: 2 },
                { start: 2, end: 4 },
                { start: 4, end: 6 },
                { start: 6, end: 8 },
                { start: 8, end: 10 },
                { start: 10, end: 12 },
                { start: 12, end: 14 },
                { start: 14, end: 16 },
                { start: 16, end: 18 },
                { start: 18, end: 20 },
                { start: 20 },
            ],
            [MODES_OF_TRAVEL.RAIL.code]: [
                { start: 5, end: 10 },
                { start: 10, end: 15 },
                { start: 15, end: 20 },
                { start: 20, end: 25 },
                { start: 25, end: 30 },
                { start: 30, end: 35 },
                { start: 35, end: 40 },
                { start: 40, end: 45 },
                { start: 45, end: 50 },
                { start: 50 },
            ],
        },
    },
    CIRCUITY: {
        code: "trip_circuity_bins",
        name: "Trip Circuity",
        uiStateName: "tripCircuity",
        unit: "ratio",
        units: null,
        showInViz3: true,
        chartType: "totalTrafficByTripCircuity",
        binTypeAccessor: "enable_trip_circuity",
        columnHeaders: [],
        defaultValue: [
            { start: 1, end: 2 },
            { start: 2, end: 3 },
            { start: 3, end: 4 },
            { start: 4, end: 5 },
            { start: 5, end: 6 },
            { start: 6 },
        ],
        minValue: null,
        maxValue: null,
        rangeFunction: null,
        defaultValueForTravelMode: null,
    },
    PERCENTILE: {
        code: "speed_percentile_bins",
        name: "Speed Percentile",
        uiStateName: "speedPercentile",
        units: {
            MPH: SPEED_UNITS.MPH.id,
            KPH: SPEED_UNITS.KPH.id,
        },
        showInViz3: false,
        chartType: "",
        binTypeAccessor: "enable_speed_percentile",
        columnHeaders: ["Percentile"],
        defaultValue: null,
        minValue: 1,
        maxValue: 99,
        rangeFunction: getOnlyStartPresetRange,
        presetOptions: {
            Default: () => getOnlyStartPresetFromArray([5, 15, 85, 95]),
            "Standard Deviation": () => getOnlyStartPresetFromArray([2, 16, 50, 84, 98]),
            "Increments of 5": () => getOnlyStartPresetRange(5, 95, 5),
        },
        defaultValueForTravelMode: null,
    },
    TRAVEL_TIME_PERCENTILE: {
        code: "duration_percentile_bins",
        name: "Travel Time Percentile",
        uiStateName: "travelTimePercentile",
        unit: "minutes",
        units: null,
        showInViz3: false,
        chartType: "",
        binTypeAccessor: "enable_duration_percentile",
        columnHeaders: ["Travel Time Percentile"],
        defaultValue: null,
        minValue: 1,
        maxValue: 99,
        rangeFunction: getOnlyStartPresetRange,
        presetOptions: {
            Default: () => getOnlyStartPresetFromArray([5, 50, 80, 90, 95]),
            "Increments of 5": () => getOnlyStartPresetRange(5, 95, 5),
        },
        defaultValueForTravelMode: null,
    },
} as const;

export const TRIP_BIN_TYPES_LIST = Object.values(TRIP_BIN_TYPES);
export type TAllTripBinTypes = typeof TRIP_BIN_TYPES[keyof typeof TRIP_BIN_TYPES];

export const SEGMENT_TYPES = {
    DURATION: {
        code: "duration",
        name: "Travel Time",
        uiStateName: "segmentDuration",
        binType: {
            ...TRIP_BIN_TYPES.DURATION,
            name: "Segment Travel Time",
            // chartType: "totalTrafficBySegmentTripDuration", //Not yet done in BE
        },
        buttonText: `segment travel time (${TRIP_BIN_TYPES.DURATION.unit})`,
        helpText: SEGMENT_TYPES_HELP_TEXTS.DURATION,
        isAlwaysOn: true,
    },
    SPEED: {
        code: "speed",
        name: "Segment Speed",
        uiStateName: "segmentSpeed",
        binType: {
            ...TRIP_BIN_TYPES.SPEED,
            name: "Segment Speed",
            //chartType: "totalTrafficBySegmentTripSpeed", //Not yet done in BE
        },
        buttonText: {
            MPH: `segment speed (${TRIP_BIN_TYPES.SPEED.units.MPH})`,
            KPH: `segment speed (${TRIP_BIN_TYPES.SPEED.units.KPH})`,
        },
        helpText: {
            MPH: SEGMENT_TYPES_HELP_TEXTS.SPEED_MPH,
            KPH: SEGMENT_TYPES_HELP_TEXTS.SPEED_KPH,
        },
        isAlwaysOn: true,
    },
    PERCENTILE: {
        code: "percentile",
        name: "Speed Percentile",
        uiStateName: "speedPercentile",
        binType: TRIP_BIN_TYPES.PERCENTILE,
        buttonText: "Speed Percentiles",
        helpText: {
            MPH: SEGMENT_TYPES_HELP_TEXTS.PERCENTILE_MPH,
            KPH: SEGMENT_TYPES_HELP_TEXTS.PERCENTILE_KPH,
            // specific helpTexts for the analysis type
            [ANALYSIS_TYPES.SEGMENT.id]: {
                MPH: SEGMENT_TYPES_HELP_TEXTS.PERCENTILE_MPH,
                KPH: SEGMENT_TYPES_HELP_TEXTS.PERCENTILE_KPH,
            },
            [ANALYSIS_TYPES.NETWORK_PERFORMANCE_SEGMENT.id]: {
                MPH: SEGMENT_TYPES_HELP_TEXTS.PERCENTILE_MPH,
                KPH: SEGMENT_TYPES_HELP_TEXTS.PERCENTILE_KPH,
            },
            [ANALYSIS_TYPES.OD.id]: {
                MPH: SEGMENT_TYPES_HELP_TEXTS.OD_PERCENTILE_MPH,
                KPH: SEGMENT_TYPES_HELP_TEXTS.OD_PERCENTILE_KPH,
            },
            [ANALYSIS_TYPES.ODMF.id]: {
                MPH: SEGMENT_TYPES_HELP_TEXTS.OD_PERCENTILE_MPH,
                KPH: SEGMENT_TYPES_HELP_TEXTS.OD_PERCENTILE_KPH,
            },
        },
        isAlwaysOn: false,
    },
    TRAVEL_TIME_PERCENTILE: {
        code: "travelTimePercentile",
        name: "Travel Time Percentile",
        uiStateName: "travelTimePercentile",
        binType: TRIP_BIN_TYPES.TRAVEL_TIME_PERCENTILE,
        buttonText: "Travel Time Percentiles",
        helpText: SEGMENT_TYPES_HELP_TEXTS.TRAVEL_TIME_PERCENTILE,
        isAlwaysOn: false,
    },
} as const;
export const SEGMENT_TYPES_LIST = Object.values(SEGMENT_TYPES);
export type TSegmentType = typeof SEGMENT_TYPES[keyof typeof SEGMENT_TYPES];

export const EMPTY_TRIP_BIN_ADD_INPUT = {
    start: "",
    end: "",
    increment: "",
};

export const TRAVELER_BIN_TYPES = {
    EDUCATION: {
        code: "education_bins",
        name: "Education",
        showInViz3: true,
        chartType: "totalTrafficByEducation",
        bins: [
            "No High School Diploma",
            "High School Diploma",
            "Some College",
            "Bachelors Degree",
            "Graduate Degree",
        ],
    },
    FAMILY_STATUS: {
        code: "family_status_bins",
        name: "Family Status",
        showInViz3: true,
        chartType: "totalTrafficByFamilyStatus",
    },
    INCOME: {
        code: "income_bins",
        name: "Household Income",
        showInViz3: true,
        chartType: "totalTrafficByIncome",
    },
    RACE: {
        code: "race_bins",
        name: "Race/Ethnicity",
        showInViz3: true,
        chartType: "totalTrafficByRace",
    },
    TRIP_PURPOSE: {
        code: "trip_purpose_bins",
        name: "Trip Purpose",
        showInViz3: true,
        chartType: "totalTrafficByTripPurpose",
        bins: ["Home-based work", "Home-based other", "Non-home-based"],
        getIsHidden: (travelModeCode?: TTravelMode["code"]) =>
            travelModeCode
                ? [MODES_OF_TRAVEL.BUS.id, MODES_OF_TRAVEL.RAIL.id].includes(travelModeCode)
                : false,
    },
};

export const TRAVELER_BIN_TYPES_LIST = Object.values(TRAVELER_BIN_TYPES);

export const TRAVELER_BIN_TYPES_US_CENSUS_2020 = {
    RACE_ETHNICITY: {
        code: "race_ethnicity_bins",
        name: "Race/Ethnicity",
        chartType: "totalTrafficByRace",
        bins: [
            [
                "White",
                "Black",
                "American Indian",
                "Asian",
                "Pacific Islander",
                "Other Race",
                "Multiple Races",
            ],
            ["Hispanic", "Non-Hispanic"],
        ],
    },
    FOREIGN_BORN: {
        code: "foreign_born_bins",
        name: "Foreign Born",
        chartType: "totalTrafficByForeignBorn",
        bins: ["Foreign Born", "Non-foreign Born"],
    },
    ENGLISH_PROFICIENCY: {
        code: "english_proficiency_bins",
        name: "Limited English Proficiency",
        chartType: "totalTrafficByLimitedEnglish",
        bins: ["Speak English less than 'very well'"],
    },
    DISABILITY_STATUS: {
        code: "disability_status_bins",
        name: "Disability Status",
        chartType: "totalTrafficByDisabilityStatus",
        bins: ["With a disability", "Without a disability"],
    },
    EDUCATION_STATUS: {
        code: "education_status_bins",
        name: "Education Status",
        chartType: "totalTrafficByEducation",
        bins: [
            "Less than 9th grade",
            "9th to 12th grade, no diploma",
            "High school graduate",
            "Some college, no degree",
            "Associate's degree",
            "Bachelor's degree",
            "Graduate or professional degree",
        ],
    },
    HOUSEHOLD_INCOME: {
        code: "household_income_bins",
        name: "Household Income",
        chartType: "totalTrafficByIncome",
        bins: [
            "Income Less than 10K",
            "Income 10K to 15K",
            "Income 15K to 20K",
            "Income 20K to 25K",
            "Income 25K to 30K",
            "Income 30K to 35K",
            "Income 35K to 40K",
            "Income 40K to 45K",
            "Income 45K to 50K",
            "Income 50K to 60K",
            "Income 60K to 75K",
            "Income 75K to 100K",
            "Income 100K to 125K",
            "Income 125K to 150K",
            "Income 150K to 200K",
            "Income More than 200K",
        ],
    },
    HOUSEHOLD_SIZE: {
        code: "household_size_bins",
        name: "Household Size/Family Status",
        chartType: "totalTrafficByFamilyStatus",
        bins: [
            "With No Kids",
            "With Kids",
            "With Kids under 6 years",
            "With Kids between 6-17 years",
        ],
    },
    HOUSING_TENURE: {
        code: "housing_tenure_bins",
        name: "Housing Tenure",
        chartType: "totalTrafficByHousingTenure",
        bins: ["Owner occupied", "Renter occupied"],
    },
    UNITS_IN_STRUCTURE: {
        code: "units_in_structure_bins",
        name: "Units in Structure",
        chartType: "totalTrafficByUnitsInStructure",
        bins: [
            "1 Unit Structure",
            "2 Unit Structure",
            "3-4 Unit Structure",
            "5-9 Unit Structure",
            "10-19 Unit Structure",
            "20-49 Unit Structure",
            "50+ Unit Structure",
            "Mobile homes, RV, boat, van, other",
        ],
    },
    VEHICLE_OWNERSHIP: {
        code: "vehicle_ownership_bins",
        name: "Vehicle Ownership",
        chartType: "totalTrafficByVehicleOwnership",
        bins: [
            "No vehicle available",
            "1 vehicle available",
            "2 vehicles available",
            "3 or more vehicles available",
        ],
    },
    EMPLOYMENT_INDUSTRY: {
        code: "employment_industry_bins",
        name: "Employment Industry & Occupation",
        chartType: "totalTrafficByEmploymentIndustry",
        bins: [
            "Agriculture, forestry, fishing, hunting, mining",
            "Construction",
            "Manufacturing",
            "Wholesale trade",
            "Retail trade",
            "Transportation, warehousing, utilities",
            "Information",
            "Finance and insurance, and real estate and rental and leasing",
            "Professional, scientific, management, and administrative and waste management services",
            "Educational services, and health care and social assistance",
            "Arts, entertainment, and recreation, and accommodation and food services",
            "Other services (except public administration)",
            "Public administration",
            "Military",
            "Not employed",
        ],
    },
    EMPLOYMENT_WORKER_CLASS: {
        code: "employment_worker_class_bins",
        name: "Employment Class of Worker",
        chartType: "totalTrafficByEmploymentClass",
        bins: [
            "Private wage and salary workers",
            "Government workers",
            "Self-employed workers",
            "Unpaid family workers",
            "Military",
            "Not employed",
        ],
    },
    TRIP_PURPOSES: {
        code: TRAVELER_BIN_TYPES.TRIP_PURPOSE.code,
        name: TRAVELER_BIN_TYPES.TRIP_PURPOSE.name,
        chartType: "totalTrafficByTripPurpose",
        bins: ["Home to Work", "Home to Other", "Non-Home Based Trip"],
    },
};

export const TRAVELER_BIN_TYPES_US_CENSUS_2020_LIST = Object.values(
    TRAVELER_BIN_TYPES_US_CENSUS_2020,
);

export type TTravelerBinTypesUSCensus2020 =
    typeof TRAVELER_BIN_TYPES_US_CENSUS_2020[keyof typeof TRAVELER_BIN_TYPES_US_CENSUS_2020];

//Traveler params for Canada analysis
export const TRAVELER_PARAMS_CA = {
    TRIP_INCOMES: {
        code: TRAVELER_BIN_TYPES.INCOME.code,
        name: TRAVELER_BIN_TYPES.INCOME.name,
        header: "Canada Analyses (CAD$)",
        bins: [
            "Less than 10K",
            "10K to 20K",
            "20K to 30K",
            "30K to 40K",
            "40K to 50K",
            "50K to 60K",
            "60K to 80K",
            "80K to 100K",
            "More than 100K",
        ],
        getIsHidden: () => false,
    },
    TRIP_EDUCATIONS: { ...TRAVELER_BIN_TYPES.EDUCATION, getIsHidden: () => false },
    TRIP_RACES: {
        code: TRAVELER_BIN_TYPES.RACE.code,
        name: TRAVELER_BIN_TYPES.RACE.name,
        header: "Canada Race/Ethnicity Bins",
        bins: ["White", "Black", "Asian", "Other Race", "Multiple Races", "Hispanic"],
        getIsHidden: () => false,
    },
    TRIP_FAMILIES: {
        code: TRAVELER_BIN_TYPES.FAMILY_STATUS.code,
        name: TRAVELER_BIN_TYPES.FAMILY_STATUS.name,
        bins: ["With Kids", "With No Kids"],
        getIsHidden: () => false,
    },
    TRIP_PURPOSES: { ...TRAVELER_BIN_TYPES.TRIP_PURPOSE },
};

type TTravelerParamsCa = keyof typeof TRAVELER_PARAMS_CA;

//Traveler params for US analysis with non-2022 data periods
export const TRAVELER_PARAMS_US = {
    TRIP_INCOMES: {
        code: TRAVELER_BIN_TYPES.INCOME.code,
        name: TRAVELER_BIN_TYPES.INCOME.name,
        header: "US Analyses (USD$)",
        bins: [
            "Less than 20K",
            "20K to 35K",
            "35K to 50K",
            "50K to 75K",
            "75K to 100K",
            "100K to 125K",
            "125K to 150K",
            "150K to 200K",
            "More than 200K",
        ],
        getIsHidden: () => false,
    },
    TRIP_EDUCATIONS: { ...TRAVELER_BIN_TYPES.EDUCATION, getIsHidden: () => false },
    TRIP_RACES: {
        code: TRAVELER_BIN_TYPES.RACE.code,
        name: TRAVELER_BIN_TYPES.RACE.name,
        header: "US Race/Ethnicity Bins",
        bins: [
            "White",
            "Black",
            "American Indian",
            "Asian",
            "Pacific Islander",
            "Other Race",
            "Multiple Races",
            "Hispanic",
        ],
        getIsHidden: () => false,
    },
    TRIP_FAMILIES: {
        code: TRAVELER_BIN_TYPES.FAMILY_STATUS.code,
        name: TRAVELER_BIN_TYPES.FAMILY_STATUS.name,
        bins: [
            "With Kids",
            "With No Kids",
            "With Kids under 6 years",
            "With Kids between 6-17 years",
        ],
        getIsHidden: () => false,
    },
    TRIP_PURPOSES: { ...TRAVELER_BIN_TYPES.TRIP_PURPOSE },
};

type TTravelerParamsUs = keyof typeof TRAVELER_PARAMS_US;
export type TTravelerParams = TTravelerParamsUs | TTravelerParamsCa;

//Traveler params for US analyses with only Census 2020 data periods (from 2019 forward) which represents US Census 2020
export const TRAVELER_PARAMS_US_CENSUS_2020 = {
    EQUITY_DEMOGRAPHICS: {
        code: "equity_demographics_bins",
        name: "Equity Demographics",
        uiStateName: "equityDemographicsBin",
        binTypeAccessor: "enable_trav_equity_us",
        chartType: "",
        bins: [
            TRAVELER_BIN_TYPES_US_CENSUS_2020.RACE_ETHNICITY,
            TRAVELER_BIN_TYPES_US_CENSUS_2020.FOREIGN_BORN,
            TRAVELER_BIN_TYPES_US_CENSUS_2020.ENGLISH_PROFICIENCY,
            TRAVELER_BIN_TYPES_US_CENSUS_2020.DISABILITY_STATUS,
        ],
    },
    EDUCATION_INCOME: {
        code: "education_income_bins",
        name: "Education/Income",
        uiStateName: "educationIncomeBin",
        binTypeAccessor: "enable_trav_income_us",
        chartType: "",
        bins: [
            TRAVELER_BIN_TYPES_US_CENSUS_2020.EDUCATION_STATUS,
            TRAVELER_BIN_TYPES_US_CENSUS_2020.HOUSEHOLD_INCOME,
        ],
    },
    HOUSEHOLD_CHARACTERISTICS: {
        code: "household_characteristics_bins",
        name: "Household Characteristics",
        uiStateName: "householdCharacteristicsBin",
        binTypeAccessor: "enable_trav_household_us",
        chartType: "",
        bins: [
            TRAVELER_BIN_TYPES_US_CENSUS_2020.HOUSEHOLD_SIZE,
            TRAVELER_BIN_TYPES_US_CENSUS_2020.HOUSING_TENURE,
            TRAVELER_BIN_TYPES_US_CENSUS_2020.UNITS_IN_STRUCTURE,
            TRAVELER_BIN_TYPES_US_CENSUS_2020.VEHICLE_OWNERSHIP,
        ],
    },
    EMPLOYMENT_CHARACTERISTICS: {
        code: "employment_characteristics_bins",
        name: "Employment Characteristics",
        uiStateName: "employmentCharacteristicsBin",
        binTypeAccessor: "enable_trav_employment_us",
        chartType: "",
        bins: [
            TRAVELER_BIN_TYPES_US_CENSUS_2020.EMPLOYMENT_INDUSTRY,
            TRAVELER_BIN_TYPES_US_CENSUS_2020.EMPLOYMENT_WORKER_CLASS,
        ],
    },
    TRIP_PURPOSES: {
        code: TRAVELER_BIN_TYPES.TRIP_PURPOSE.code,
        name: TRAVELER_BIN_TYPES.TRIP_PURPOSE.name,
        uiStateName: "tripPurposeBin",
        binTypeAccessor: "enable_trav_trip_purpose_us",
        chartType: "totalTrafficByTripPurpose",
        bins: [TRAVELER_BIN_TYPES_US_CENSUS_2020.TRIP_PURPOSES],
    },
};

export const TRAVELER_PARAMS_US_CENSUS_2020_LIST = Object.values(TRAVELER_PARAMS_US_CENSUS_2020);
export type TTravelerParamsUSCensus2020Key = keyof typeof TRAVELER_PARAMS_US_CENSUS_2020;
export type TTravelerParamsUSCensus2020 =
    typeof TRAVELER_PARAMS_US_CENSUS_2020[keyof typeof TRAVELER_PARAMS_US_CENSUS_2020];

export const HWL_ZONE_INTERSECTION_TYPES = {
    TRIP_ALL: {
        code: "A",
        display:
            "Include all intersections (start, end, pass-through), regardless of pass-through setting",
    },
    TRIP_START_END: {
        code: "S",
        display: "Use original zone settings",
    },
} as const;
export const HWL_ZONE_INTERSECTION_TYPES_LIST = Object.values(HWL_ZONE_INTERSECTION_TYPES);
export const DEFAULT_HWL_ZONE_INTERSECTION_TYPE = HWL_ZONE_INTERSECTION_TYPES.TRIP_START_END;
export type THWLZoneIntersectionType =
    typeof HWL_ZONE_INTERSECTION_TYPES[keyof typeof HWL_ZONE_INTERSECTION_TYPES];

export const HWL_FILTER_OPTIONS = {
    VISITOR: {
        code: "hwl_enable_visitor",
        display: "Visitors - Home and work location areas of devices that visit the zone",
        defaultValue: true,
    },
    RESIDENT: {
        code: "hwl_enable_resident",
        display: "Residents - Work location areas of devices that reside in the zone",
        defaultValue: false,
    },
    WORKER: {
        code: "hwl_enable_worker",
        display: "Workers - Home location areas of devices that work in the zone",
        defaultValue: false,
    },
} as const;
export const HWL_FILTER_OPTIONS_LIST = Object.values(HWL_FILTER_OPTIONS);
export type THWLFilterOption = typeof HWL_FILTER_OPTIONS[keyof typeof HWL_FILTER_OPTIONS];

export const ROUTE_SEGMENTS = {
    MOTORWAY: {
        id: "motorway",
        name: "Motorway",
        defaultState: true,
    },
    TRUNK: {
        id: "trunk",
        name: "Trunk",
        defaultState: true,
    },
    PRIMARY: {
        id: "primary",
        name: "Primary",
        defaultState: true,
    },
    SECONDARY: {
        id: "secondary",
        name: "Secondary",
        defaultState: true,
    },
    TERTIARY: {
        id: "tertiary",
        name: "Tertiary",
        defaultState: true,
    },
    RESIDENTIAL: {
        id: "residential",
        name: "Residential",
        defaultState: false,
    },
} as const;

export const ROUTE_SEGMENT_TYPES_LIST = Object.values(ROUTE_SEGMENTS);
export type TRouteSegment = typeof ROUTE_SEGMENTS[keyof typeof ROUTE_SEGMENTS];

export const RESET_TRAVELER_ATTRIBUTES_WARNING_MESSAGES = {
    NOT_AVAILABLE_FOR_CANADA_CVD:
        "Traveler Attributes are not available for Canada with All Vehicles CVD+ travel mode and will be reset. Are you sure you want to proceed?",
    NOT_AVAILABLE:
        "Traveler Attributes will not be available for the selected data period combination in US. Are you sure you want to proceed?",
    RESET: "Traveler Attributes will be reset. Are you sure you want to proceed?",
};
