import { useEffect, useRef, useState } from "react";
import { useAppDispatch } from "@app/store/hooks";
import { LayerGroupApiService } from "@common/services/server/layerGroupApi.service";
import { useZoom } from "@common/components/baseMap";
import { toSourceId } from "@common/components/baseMap/baseMap.helpers";
import { removeSource } from "@common/utils/mapUtils";
import { OSM_VINTAGE } from "@app/store/staticData/state/staticData.constants";
import { setOSMLayersVintageDate } from "@app/store/staticData/state/staticData.actions";
import type { Map } from "mapbox-gl";
import type { IOSMMetaLayer } from "@common/services/server/layerGroupApi.types";
import { MIN_INTERACTIVE_ZOOM, TOSMLayer } from "../mapLayers.constants";

type TState = {
    source: string | null;
    windshaftLayers: Array<IOSMMetaLayer>;
};

const INITIAL_VALUE = {
    windshaftLayers: [],
    source: null,
} as TState;

export const useWindshaftLayers = (
    map: Map | null,
    {
        osmLayerCodes,
    }: {
        osmLayerCodes: Array<TOSMLayer["layerCode"]>;
    },
) => {
    const [windshaftLayers, setWindshaftLayers] = useState(INITIAL_VALUE.windshaftLayers);

    const source = useRef(INITIAL_VALUE.source);
    const zoom = useZoom(map, { isDebounced: true });

    const dispatch = useAppDispatch();

    const shouldDoRequest = zoom >= MIN_INTERACTIVE_ZOOM.OSM;

    // Do a new layergroup request on osm layers
    useEffect(() => {
        if (!map || !shouldDoRequest || !osmLayerCodes?.length) return undefined;

        LayerGroupApiService.getOsmLayerGroup({
            layer_types: osmLayerCodes,
        }).then(response => {
            const {
                layergroupid,
                mvtTileURLTemplate,
                min_zoom: minZoom,
                max_zoom: maxZoom,
                metadata,
            } = response.data;
            const sourceId = toSourceId(layergroupid);
            const firstOsmLayer = metadata.layers?.[0] || {};
            const osmVintageDate = {
                year_num: firstOsmLayer.vintage_year_num || OSM_VINTAGE.year_num,
                month_num: firstOsmLayer.vintage_month_num || OSM_VINTAGE.month_num,
            };

            if (source.current && map.getSource(source.current)) {
                removeSource(map, source.current);
            }

            map.addSource(sourceId, {
                type: "vector",
                tiles: [mvtTileURLTemplate],
                minzoom: minZoom,
                maxzoom: maxZoom,
                promoteId: "osm_id",
            });
            source.current = sourceId;
            dispatch(setOSMLayersVintageDate(osmVintageDate));
            setWindshaftLayers(metadata.layers);
        });

        return () => {
            removeSource(map, source.current);
        };
    }, [map, shouldDoRequest, osmLayerCodes, dispatch]);

    return {
        source: source.current,
        windshaftLayers,
    };
};
